.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 28px auto;
  max-width: 90%;

  @media (max-width: 1100px) {
    margin-top: 50px;
    margin-bottom: 7px;
  }
}

.questionsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
