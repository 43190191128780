@font-face {
  font-family: "Inter4";
  src: url("../fonts/Inter-Regular.woff2") format('woff2'),
  url("../fonts/Inter-Regular.woff") format('woff');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Inter7";
  src: url("../fonts/Inter-Bold.woff2") format('woff2'),
  url("../fonts/Inter-Bold.woff") format('woff');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Sf4";
  src: url("../fonts/SFUIDisplay-Light.woff2") format('woff2'),
  url("../fonts/SFUIDisplay-Light.woff") format('woff');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'SF5';
  src: url("../fonts/SFUIDisplay-Medium.woff2") format('woff2'),
  url("../fonts/SFUIDisplay-Medium.woff") format('woff');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'SF7';
  src: url("../fonts/SFUIDisplay-Bold.woff2") format('woff2'),
  url("../fonts/SFUIDisplay-Bold.woff") format('woff');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}
