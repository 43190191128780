.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: min(1141px, 90%);
  padding: 46px 30px;
  background-color: var(--s-gray);
  border-radius: var(--br-main);
  border: none;
  margin: 0 auto;
  position: relative;

  @media (max-width: 1100px) {
    width: min(569px, 90%);
    gap: 12px;
    padding: 24px;
  }
}

.containerData {
  display: flex;
  justify-content: space-between;
}

.containerItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 48px;
  row-gap: 24px;

  @media (max-width: 1100px) {
    column-gap: 24px;
    row-gap: 12px;
    justify-content: flex-start;
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 530px;

  h4 {
    color: var(--yellow);
    font-size: 32px;
    line-height: 39px;
    font-family: "Inter7", sans-serif;
  }

  @media (max-width: 1100px) {
    width: 100%;

    h4 {
      font-size: 22px;
      line-height: 27px;
    }
  }
}

.whatLearnTop {
  position: absolute;
  top: -120px;
  right: 130px;
  filter: drop-shadow(61px 72px 55px rgba(0, 0, 0, 0.14));
}

.whatLearnImage {
  position: absolute;
  top: 32px;
  right: 60px;
}

.doc {
  position: absolute;
  right: 30px;
  bottom: 46px;
  gap: 8px;
  width: 305px;
  height: 51px;
  border: 1px solid #32284B;
  box-shadow:  0 3px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  font-family: "Inter7", sans-serif;
  background-color: inherit;
  transition: all .3s ease-out;
  color: var(--main-black);

  &:hover {
    transform: scale(0.8, 0.8);
    transition: all .3s ease-out;
  }

  @media (max-width: 1100px) {
    position: static;
    width: 100%;
  }
}

.programContainer {
    display: flex;
    width: min(1141px, 90%);
    background-color: var(--s-gray);
    border-radius: var(--br-main);
    border: none;
    margin: 0 auto;
    position: relative;

    @media (max-width: 1100px) {
      width: min(569px, 90%);
      gap: 12px;
      padding: 24px;
    }
}

.leftPart {
  width: 444px;
  min-width: 444px;
  padding: 46px 0 160.5px 30px;
  gap: 24px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1100px) {
    padding: 0;
    min-width: unset;
    width: 100%;
    height: 364px;
  }

  @media (max-width: 620px) {
    height: 383.5px;
  }

  @media (max-width: 586px) {
    height: 403px;
  }

  @media (max-width: 572px) {
    height: 422.5px;
  }

  @media (max-width: 436px) {
    height: 442px;
  }

  @media (max-width: 408px) {
    height: 461.5px;
  }

  @media (max-width: 384px) {
    height: 481px;
  }
}

.image {
  height: 100%;
  width: 100%;
  background-image: url("../../assets/images/whatLearnSecondPage.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--br-main);
}

.dataBlock {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  input {
    padding: 15px;
    border: 1px solid #E2E2EA;
    border-radius: 12px;
    box-sizing: border-box;
    outline: none;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;

    &::placeholder {
      color: #B5B5BE;
    }
  }
}

.download {
  height: 45px;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.33)), url("../../assets/images/loginButton.png");
  box-shadow: 0 12px 18px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-size: 16px;
  line-height: 130%;
  font-family: "Inter7", sans-serif;
  color: var(--white);
  border: none;
  background-size: 100%;
  transition: all .3s ease-out;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  box-sizing: border-box;
  cursor: pointer;


}

.openedLink {
  opacity: 1;

  &:hover {
    background-size: 200%;
    transform: scale(0.8, 0.8);
    transition: all .3s ease-out;
  }
}

.disabledLink {
  opacity: 0.2;
}

.backButton {
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  text-decoration-line: underline;
  border: none;
  background: inherit;
}
