.container {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: var(--white);
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: none;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 24px;

  a {
    color: #505050;
    text-decoration: none;
  }
}

.mainHead {
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    cursor: pointer;
  }
}

.headBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.header {
  font-size: 14px;
  line-height: 26px;

  .teach {
    color: #929292
  }

  .me {
    color: #515151
  }

  .grow {
    color: #32284B
  }
}

.start {
  height: 45px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.33)), url("../../assets/images/loginButton.png");
  box-shadow: 0 12px 18px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-size: 16px;
  line-height: 130%;
  font-family: "Inter7", sans-serif;
  color: var(--white);
  border: none;
  background-size: 100%;
  transition: background-size .3s ease-out;

  &:hover {
    background-size: 200%;
  }
}
