.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  width: min(90%, 860px);

  @media (max-width: 1100px) {
    gap: 64px;
  }
}

.dataBlock {
  position: relative;
  width: 100%;
  height: 787px;

  @media (max-width: 1100px) {
    height: unset;
  }
}

.topCircle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.middleCircle {
  position: absolute;
  top: 17px;
  left: 50%;
  transform: translateX(-50%);
}

.bottomCircle {
  position: absolute;
  top: 34px;
  left: 50%;
  transform: translateX(-50%);
}

.whoNeedsRocket {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 121px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.05;
  fill: red;
  gap: 10px;
}

.rocketCenter {
  position: absolute;
  top: 100px;
}

.person {
  display: flex;
  gap: 24px;
  width: 278px;
  height: 295px;
  text-align: center;
  position: absolute;
  align-items: flex-start;
  transition: all .3s ease-out;
  background: rgba(255, 255, 255, 0.44);
  backdrop-filter: blur(4px);
  border-radius: 12px;
  padding: 24px;

  .personMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 230px;
    min-width: 230px;

    img {
      height: 182px;
    }

    .hoverMe {
      position: absolute;
      bottom: 24px;
      font-style: italic;
    }

    @media (max-width: 1100px) {
      img {
        position: absolute;
        height: 120px;
        top: -52px;
      }
    }
  }

  &:hover {
    @media (min-width: 1101px) {
      align-items: center;
      width: 693px;
      z-index: 1;

      .hoverMe {
        visibility: hidden;
      }

      .text {
        display: flex;
        flex-direction: column;
        gap: 12px;
        visibility: visible;
        opacity: 1;
        transition: transform .2s ease-out .2s, opacity .2s ease-out .2s;
        font-size: 17px;
        line-height: 130%;
        align-self: center;
        position: static;
        transform: scale(1);
      }
    }
  }

  @media (max-width: 1100px) {
    width: min(100%, 400px);
    display: flex;
    flex-direction: column;
    height: unset;
    padding: 77px 18px 18px 18px;
    align-items: center;
    position: relative;
  }
}

.person:hover~.whoNeedsRocket .rocketBottom {
  @media (min-width: 1101px) {
    fill: red;
    animation: scale 1s alternate infinite ease-in;
  }
}

.contentBlock {
  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
  }
}

.contentBlock:hover:has(.person:hover) .person:not(:hover) {
  @media (min-width: 1101px) {
    opacity: 0.32;
  }
}

@keyframes scale {
  0%   {transform: scale(1, .8) translateY(-10px); }
  100% {transform: scale(1, 1.2) translateY(20px);}
}

.firstPerson {
  top: 23px;
  left: 0;

  @media (max-width: 1100px) {
    top: unset;
    left: unset;
  }
}

.secondPerson {
  top: 23px;
  right: 0;

  @media (max-width: 1100px) {
    top: unset;
    right: unset;
  }
}

.thirdPerson {
  bottom: 23px;
  left: 0;

  @media (max-width: 1100px) {
    bottom: unset;
    left: unset;
  }
}

.lastPerson {
  bottom: 23px;
  right: 0;

  @media (max-width: 1100px) {
    bottom: unset;
    right: unset;
  }
}

.position {
  font-family: "Inter7", sans-serif;
  font-size: 22px;
  line-height: 27px;
  white-space: nowrap;

  @media (max-width: 1100px) {
    font-size: 15px;
    line-height: 18px;
  }
}

.text {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transform: scale(.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;

  li {
    margin-left: 24px;
    text-align: left;
  }

  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    gap: 12px;
    visibility: visible;
    opacity: 1;
    transition: transform .2s ease-out .2s, opacity .2s ease-out .2s;
    line-height: 130%;
    position: static;
    transform: scale(1);
    font-size: 15px;
    align-self: flex-start
  }
}
