.container {
  width: 369px;
  height: fit-content;
  background: #FFFFFF;
  //box-shadow: 0 20px 32px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: flex-end;
  margin: 0 12px;

  @media (max-width: 1100px) {
    width: min(400px, 90%);
    margin: 0 auto;
  }
}

.insideContainer {
  display: flex;
  gap: 8px;

  img {
    width: 38px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .name {
      font-size: 16px;
      line-height: 19px;
      font-family: "Inter7", sans-serif;
    }

    .position {
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.text {
  font-size: 16px;
  line-height: 170%;
}

.sign {
  align-self: flex-start;
}
