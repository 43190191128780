.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  width: min(569px, 90%);
  align-items: center;
}

.mobileImage {
  margin-top: 24px;
  width: 100%;
  background: linear-gradient(23.96deg, #905DB8 0%, #7031D8 100%), #FFFFFF;
  border-radius: 18px;
}

.container {
  display: flex;
  flex-direction: column;
  width: min(1141px, 90%);
  margin: 0 auto;
  position: relative;
  border-radius: 18px;
  background: linear-gradient(23.96deg, #905DB8 0%, #7031D8 100%);
  padding: 32px;
  gap: 10px;
}

.topImage {
  position: absolute;
  filter: drop-shadow(61px 72px 55px rgba(0, 0, 0, 0.14));
  right: -10px;
  //top: -100px;
  top: -300px;
}

.leftImage {
  position: absolute;
  filter: drop-shadow(61px 72px 55px rgba(0, 0, 0, 0.14));
  left: 60px;
  //top: 730px;
  top: 420px;
  z-index: 1;
}


.rightImage {
  position: absolute;
  filter: drop-shadow(61px 72px 55px rgba(0, 0, 0, 0.14));
  right: 60px;
  //top: 500px;
  top: 380px;
  z-index: 1;
}

.bottomBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px;
  gap: 16px;
  background-color: #F9FAFE;
  border-radius: 18px;
  backdrop-filter: blur(2px);
  border: 7px solid #FFFFFF;
  text-align: center;
  min-height: 298px;
}

.typesBlock {
  display: flex;
  justify-content: flex-start;
  gap: 32px;

  @media (max-width: 1100px) {
    gap: 10px;
    margin-top: 17px;
    margin-bottom: 17px;
  }
}

.type {
  width: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  span {
    font-family: "Inter7", sans-serif;
    font-size: 17px;
    line-height: 21px;
    cursor: pointer;
  }

  @media (max-width: 1100px) {
    width: 164px;
    //margin-top: 24px;

    span {
      font-size: 15px;
      line-height: 18px;
    }
  }
}

.active {
  span {
    color: #232323;
  }

  hr {
    width: 100%;
    height: 3px;
    background: linear-gradient(23.96deg, #905DB8 0%, #7031D8 100%), linear-gradient(118.16deg, #7549F2 0%, #DF58D2 47.71%, #ED787C 99.57%);
    border-radius: 28px;
    border: none;
    transition: all .3s linear;
    opacity: 1;
  }
}

.nonActive {
  span {
    color: rgba(41, 41, 41, 0.46);
    opacity: 0.6;
  }

  hr {
    width: 0;
    transition: all .3s linear;
    opacity: 0;
  }
}

.textBlock {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: min(100%, 408px);

  @media (max-width: 1100px) {
    text-align: center;
    margin-bottom: 17px;
  }
}

.text {
  font-size: 15px;
}

.topBlock {
  display: grid;
  grid-template-columns: auto 254px;
  gap: 36px;
  align-items: center;
}

.topImagesBlock {
  img {
    height: 410px;
  }
}

.topDataBlock {
  display: flex;
  flex-direction: column;
  gap: 29px;

  .smallBlock {
    max-width: 160px;
  }

  .smallBottomBlock {
    max-width: 254px;
    text-align: center;
    margin: 0 auto;
  }

  .dataBlock {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .dataBlockValue {
      font-size: 32px;
      line-height: 39px;
      font-family: "Inter7", sans-serif;
      color: var(--white);
    }

    .dataBlockText {
      font-size: 15px;
      line-height: 130%;
      color: var(--white);
    }

    @media (max-width: 1100px) {
      //max-width: 160px;

      .dataBlockValue {
        background: linear-gradient(23.96deg, #6F6BC3 0%, #7031D8 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      .dataBlockText {
        color: inherit;
      }
    }
  }
}

.topDataBlockRow {
  flex-direction: row;
  flex-wrap: wrap;
  gap: unset;
  justify-content: space-between;
  row-gap: 29px;
}

//.data {
//  display: flex;
//  flex-direction: column;
//  gap: 4px;
//  text-align: center;
//  justify-content: center;
//
//  .value {
//    font-family: "Inter7", sans-serif;
//    font-size: 32px;
//    line-height: 39px;
//    background: linear-gradient(23.96deg, #905DB8 0%, #7031D8 100%), linear-gradient(118.16deg, #7549F2 0%, #DF58D2 47.71%, #ED787C 99.57%);
//    -webkit-background-clip: text;
//    -webkit-text-fill-color: transparent;
//    background-clip: text;
//    text-fill-color: transparent;
//  }
//}
