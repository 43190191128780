.container {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding-top: 145px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-image: url("../../assets/images/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  footer {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  @media (max-width: 1100px) {
    align-items: center;
    padding-top: 102px;
    gap: 17px;
  }
}
