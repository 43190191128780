.container {
  height: 55px;
  top: 30px;
  position: fixed;
  width: calc(100% - 100px);
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 10px 28px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);

  img {
    cursor: pointer;
  }

  @media (max-width: 1100px) {
    width: calc(100% - 12px);
    height: 39px;
    top: 17px;
    background: #F3F3F3;
    border-radius: 6px;
    padding: 0 18px;
  }
}

.leftBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-left: 24px;

  @media (max-width: 1100px) {
    margin-left: 0;
  }
}

.header {
  font-size: 14px;
  line-height: 26px;

  .teach {
    color: var(--white)
  }

  .me {
    color: #515151
  }

  .grow {
    color: #32284B
  }
}
