.header {
  font-size: 40px;
  line-height: 48px;
  font-family: "Inter7", sans-serif;

  @media (max-width: 1100px) {
    font-size: 26px;
    line-height: 31px;
  }
}

.center {
  text-align: center;
  align-self: center;
}

.white {
  color: #FFFFFF;
}
