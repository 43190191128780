@import "./root", "./fonts", "./reset.css";

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
}

img{
    max-width: 100%;
    height: auto;
    display: flex;
}

button{
    cursor: pointer;
}

h2, h3, h4, h5 {
    margin: 0;
}

html, body{
    width: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    font-family: "Inter4", sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: 17px;
    line-height: 130%;
    color: var(--main-black);
    background: var(--gray);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    @media (max-width: 1100px) {
        font-size: 15px;
    }
}

.ReactCollapse--collapse {
  transition: height 300ms;
}
