:root {
  //colors
  --main-black: #32284B;
  --violet: #231D4F;
  --gray: #EBEBEB;
  --s-gray: #F7F7F7;
  --second-gray: #848199;
  --white: #FFF;
  --second-white: #F7F7F7;
  --yellow: #F4BA6D;

  //gradients
  --gr-main: linear-gradient(23.96deg, #905DB8 0%, #7031D8 100%), linear-gradient(118.16deg, #7549F2 0%, #DF58D2 47.71%, #ED787C 99.57%);
  --gr-second: linear-gradient(23.96deg, #6F6BC3 0%, #7031D8 100%), linear-gradient(23.96deg, #905DB8 0%, #7031D8 100%), linear-gradient(118.16deg, #7549F2 0%, #DF58D2 47.71%, #ED787C 99.57%);

  //border-radius
  --br-main: 18px
}
