.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  text-align: center;

  @media (max-width: 1100px) {
    max-width: 90%;
  }
}

.header {
  font-size: 58px;
  line-height: 70px;
  font-family: "Inter7", sans-serif;
  color: var(--white);

  .violet {
    background: linear-gradient(23.96deg, #9CF5B9 0%, #88EAAF 48.44%, #C9FFD1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  @media (max-width: 1100px) {
    font-size: 32px;
    line-height: 39px;
  }
}

.about {
  max-width: 569px;
  align-self: center;
  color: var(--white);

  span {
    font-family: "Inter7", sans-serif;
  }

  @media (max-width: 1100px) {
    font-size: 14px;
  }
}

.text {
  font-size: 12px;
  line-height: 15px;
}

.charactersBlock {
  display: flex;
  align-self: center;
  gap: 24px;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 13px;
  margin-top: -8px;

  div {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 12px;
    align-items: center;
    margin-top: 0;
    padding: unset;
    background: unset;
    border-radius: unset;
    color: var(--white);
  }
}

.learnLeftImage {
  position: absolute;
  top: 259px;
  left: calc(50% - 600px);
  filter: drop-shadow(61px 72px 55px rgba(0, 0, 0, 0.14));
}

.learnRightImage {
  position: absolute;
  top: 239px;
  right: max(calc(50% - 680px), 0px);
  filter: drop-shadow(61px 72px 55px rgba(0, 0, 0, 0.14));
}

.learnBottomImage {
  position: absolute;
  top: 428px;
  left: calc(50% - 200px);
  filter: drop-shadow(61px 72px 55px rgba(0, 0, 0, 0.14));

  @media (max-width: 1199px) {
    top: 500px;
  }
}

.learnBottomMobileImage {
  align-self: center;
  margin-top: -50px;
}
