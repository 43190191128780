.container {
  width: min(1250px, 90%);
  background: var(--gr-second);
  border-radius: 57px;
  padding: 42px 64px;
  display: grid;
  grid-template-columns: 445px auto;
  column-gap: 64px;
  align-items: center;
  position: relative;
  margin: 0 auto;

  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    border-radius: 26px;
    padding: 18px;
    gap: 24px;
    width: min(600px, 90%);
  }

  @media (max-width: 660px) {
    width: min(440px, 90%);
  }
}

.leftPart {
  width: min(445px, 100%);
  display: flex;
  flex-direction: column;
  gap: 15px;

  span {
    font-size: 14px;
    line-height: 26px;
    color: var(--white);
  }
}

.rightPart {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;

  input {
    background: inherit;
    z-index: 1;
    border: none;
    border-bottom: 2px solid var(--white);
    outline: none;
    padding-bottom: 8px;
    color: white;
    font-size: 16px;
    line-height: 26px;

    &::placeholder {
      color: #FFFFFF;
      opacity: 0.4;
      font-size: 16px;
      line-height: 26px;
    }
  }

  textarea {
    background: inherit;
    z-index: 1;
    border: none;
    border-bottom: 2px solid var(--white);
    outline: none;
    padding-bottom: 8px;
    color: white;
    font-size: 16px;
    line-height: 26px;
    resize: none;

    &::placeholder {
      color: #FFFFFF;
      opacity: 0.4;
      font-size: 16px;
      line-height: 26px;
    }

    @media (max-width: 1100px) {
      font-size: 14px;

      &::placeholder {
        font-size: 14px;
      }
    }
  }
}

.contactUsTopImage {
  position: absolute;
  top: -104px;
  right: -54px;
  z-index: 0;
  filter: drop-shadow(61px 72px 55px rgba(0, 0, 0, 0.14));
}

.contactUsBottomImage {
  position: absolute;
  bottom: -40px;
  left: -46px;
  filter: drop-shadow(61px 72px 55px rgba(0, 0, 0, 0.14));
}
