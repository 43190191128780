.button {
  height: 51px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-size: 16px;
  line-height: 19px;
  font-family: "Inter7", sans-serif;
  text-align: center;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: none;
  z-index: 1;
  transform: scale(1);
  transition: all .3s ease-out;

  @media (min-width: 1101px) {
    &:hover {
      transform: scale(0.8, 0.8);
      box-shadow: none;
      transition: all .3s ease-out;
    }
  }
}

.mini {
  width: 114px;
}

.small {
  width: 159px;
}

.middle {
  width: 244px;
}

.full {
  width: 100%;
}

.violet {
  background-color: var(--main-black);
  color: var(--white);
}

.white {
  color: var(--main-black);
  background-color: var(--white);
}
