.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  position: absolute;
  width: 351px;
  left: calc(50% - 176px);
  top: calc(50% - 239px);
  background: #FFFFFF;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  border: none;
  outline: none;
  -webkit-font-smoothing: antialiased;
}

.headContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.mainHead {
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    cursor: pointer;
  }
}

.headBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.header {
  font-size: 14px;
  line-height: 26px;

  .teach {
    color: #929292
  }

  .me {
    color: #515151
  }

  .grow {
    color: #32284B
  }
}

.headerName {
  font-size: 30px;
  line-height: 38px;
  font-family: "Inter7", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 200;

  .violet {
    background: linear-gradient(23.96deg, #905DB8 0%, #7031D8 100%), linear-gradient(118.16deg, #7549F2 0%, #DF58D2 47.71%, #ED787C 99.57%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.welcome {
  font-size: 30px;
  line-height: 38px;
  font-family: "Inter7", sans-serif;
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    cursor: pointer;
  }
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.socialsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.socialButton {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  background-color: var(--white);
  padding: 12px;
  font-size: 12px;
  color: #505050;
  width: 100%;
}

.line {
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: center;

  hr {
    background-color: #D9D9D9;
    height: 1px;
    width: 100%;
    border: none;
  }

  span {
    font-size: 12px;
    color: #505050;
    white-space: nowrap;
  }
}

.mainInputsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  .email {
    border: 1px solid #E2E2EA;
    border-radius: 12px;
    height: 45px;
    padding: 15px;
    outline: none;
    letter-spacing: 0.1px;
    font-size: 12px;
    line-height: 15px;

    &::placeholder {
      color: #B5B5BE;
    }
  }
}

.start {
  height: 40px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.33)), url("../../assets/images/loginButton.png");
  box-shadow: 0 12px 18px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-size: 16px;
  font-family: "Inter7", sans-serif;
  color: var(--white);
  border: none;
  background-size: 100%;
  transition: background-size .3s ease-out;

  &:hover {
    background-size: 200%;
  }

  &:disabled {
    opacity: .2;

    &:hover {
      background-size: 100%;
    }
  }
}

.success {
  border: 1px solid green !important;
}

.error {
  border: 1px solid red !important;
}

.loginLeft {
  position: absolute;
  max-width: fit-content;
  top: calc(50% + 110px);
  left: calc(50% - 180px);
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 760px;
}

.loginRight {
  position: absolute;
  max-width: fit-content;
  top: calc(50% + 100px);
  left: calc(50% + 336px);
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 800px;
}

.links {
  font-size: 14px;
  line-height: 130%;
  color: #505050;
  text-align: center;

  a {
    color: #505050;
    cursor: pointer;
  }
}

.forgot {
  font-size: 14px;
  line-height: 130%;
  color: #505050;
  text-align: center;
  cursor: pointer;
  text-decoration-line: underline;
}
