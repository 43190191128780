.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    font-family: "SF4", sans-serif;
    margin-bottom: 28px;

    @media (max-width: 1100px) {
        margin-bottom: 7px;
    }

    @media (max-width: 660px) {
        width: min(440px, 90%);
    }
}

.accessCardsContainer {
    //backdrop-filter: blur(17.5px);
    display: flex;
    gap: 36px;
    font-family: "SF5", sans-serif;
    justify-content: center;

    @media (max-width: 660px) {
        flex-direction: column;
        gap: 18px;
        row-gap: 18px;
    }
}

.accessCard {
    background: #FFFFFF;
    box-shadow: 0 3px 15px rgba(18, 62, 106, 0.15);
    border-radius: 26px;
    padding: 24px;
    width: 280px;
    box-sizing: border-box;

    h4 {
        font-size: 28px;
        line-height: 33px;
        margin-bottom: 15px;
        margin-top: 0;
        font-family: "SF7", sans-serif;
    }

    .accessCardText {
        font-size: 15px;
        line-height: 130%;
        color: #848199;
    }

    @media (max-width: 660px) {
        width: 100%;
    }
}

.accessCardRight {
    color: #231D4F;
}

.accessCardLeft {
    color: #848199;

    h4 {
        background: linear-gradient(23.96deg, #6F6BC3 0%, #7031D8 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .accessPricesBlock {
        span {
            background: linear-gradient(23.96deg, #6F6BC3 0%, #7031D8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }
}

.accessPricesBlock {
    display: flex;
    font-size: 15px;
    line-height: 18px;
    position: relative;
    margin-bottom: 12px;
    align-items: baseline;
    gap: 6px;

    img {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: -4px;
    }
}

.accessFirstPrice {
    opacity: 0.4;
    font-size: 24px;
    line-height: 46px;
    font-family: "SF7", sans-serif;
}

.accessSecondPrice {
    font-size: 24px;
    line-height: 46px;
    font-family: "SF7", sans-serif;
}

.accessPriceOnDate {
    color: #848199;
}

.accessBenefitsContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 36px;
}

.accessBenefit {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #848199;
}

.accessButton {
    border-radius: 24px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    color: var(--white);
    font-size: 15px;
    line-height: 18px;
}

.accessBlueButton {
    background-color: #231D4F;
    transition: all .3s ease-out;

    &:hover {
      transform: scale(0.8, 0.8);
      transition: all .3s ease-out;
    }
}

.accessGreenButton {
    background: linear-gradient(23.96deg, #6F6BC3 0%, #7031D8 100%);
    transition: all .3s ease-out;

    &:hover {
      transform: scale(0.8, 0.8);
      transition: all .3s ease-out;
    }
}
