.container {
  display: grid;
  grid-template-columns: auto 240px;
  column-gap: 32px;
  width: min(1141px, 90%);
  margin: 450px auto 0 auto;

  @media (max-width: 1100px) {
    margin: -90px auto 0 auto;
    display: flex;
    flex-direction: column;
    gap: 17px;
    width: min(569px, 90%);
  }
}

.leftBlock {
  display: grid;
  grid-template-columns: auto 396px;

  @media (max-width: 1250px) {
    grid-template-columns: auto 361px;
  }

  @media (max-width: 1100px) {
    display: flex;
  }
}

.leftPart {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  background-color: var(--white);
  border-radius: 18px 0 0 18px;
  padding: 46px 20px 42px 30px;

  @media (max-width: 1100px) {
    border-radius: 18px;
    padding: 24px;
    gap: 12px;
  }
}

.leftPartData {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1100px) {
    gap: 12px;
  }
}

.leftPartBottomBlock {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.leftPartTopBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .percent {
    font-size: 50px;
    line-height: 130%;
    font-family: "Inter7", sans-serif;
    background: linear-gradient(23.96deg, #905DB8 0%, #7031D8 100%), linear-gradient(118.16deg, #7549F2 0%, #DF58D2 47.71%, #ED787C 99.57%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  @media (max-width: 1100px) {
    gap: 12px;

    .percent {
      font-size: 32px;
      line-height: 130%;
    }
  }
}

.rigthPart {
  background: var(--gr-main);
  border-radius: 0 18px 18px 0;
  align-items: center;
  justify-content: center;
  display: flex;

  img {
    filter: drop-shadow(61px 72px 55px rgba(0, 0, 0, 0.14));
  }

  @media (max-width: 1100px) {
    display: none;
  }
}

.rightBlock {
  background: var(--gr-main);
  border-radius: var(--br-main);
  padding: 34px 24px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  cursor: pointer;
  text-decoration: none;
  transition: all .3s ease-out;

  h3 {
    word-spacing: 9999999px;
  }

  img {
    align-self: flex-end;
    margin-right: 12px;
  }

  @media (max-width: 1100px) {
    position: relative;
    padding: 21px;

    h3 {
      word-spacing: unset;
      max-width: 160px;
    }

    img {
      position: absolute;
      right: 21px;
      bottom: 0;
    }
  }

  @media (min-width: 1101px) {
    &:hover {
      transform: scale(0.8, 0.8);
      transition: all .3s ease-out;
    }
  }
}
