.container {
  width: min(1250px, 90%);
  min-height: 262px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  background: var(--gr-second);
  box-shadow: 0 20px 32px rgba(0, 0, 0, 0.25);
  border-radius: 57px;
  position: relative;
  margin: 28px auto;

  @media (max-width: 1100px) {
    border-radius: 12px;
    justify-content: flex-start;
    padding: 24px;
    min-height: 345px;
    width: min(90%, 400px);
  }
}

.insideContainer {
  display: flex;
  gap: 32px;

  div {
    display: flex;
    flex-direction: column;
    gap: 20px;

    span {
      width: 490px;
      font-size: 24px;
      line-height: 29px;
      color: var(--white);
      font-family: "Sf4", sans-serif;
    }

    .bold {
      font-family: "Sf7", sans-serif;
    }
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    gap: 130px;
    width: 100%;
  }
}

.learnHowToBuildLeftRocket {
  position: absolute;
  bottom: 0;
  left: 27px;

  @media (max-width: 1300px) {
    transform: scale(0.8, 0.8);
    left: -20px;
    bottom: -18px
  }

  @media (max-width: 1150px) {
    transform: scale(0.6, 0.6);
    left: -42px;
    bottom: -36px
  }

  @media (max-width: 1100px) {
    transform: scale(1.2, 1.2);
    left: 40px;
    bottom: 18px;
  }
}

.learnHowToBuildRightRocket {
  position: absolute;
  bottom: 0;
  right: 40px;

  @media (max-width: 1300px) {
    right: 0;
  }
}

.header {
  font-size: 32px;
  line-height: 39px;
  font-family: "Inter7", sans-serif;
  color: var(--white);
}
