.container {
  max-width: 90%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-self: center;
  position: relative;

  @media (max-width: 1100px) {
    max-width: 100%;
  }
}

.insideContainer {
  max-width: 100%;
  width: 1180px;

  @media (max-width: 1349px) {
    width: 786px;
  }

  :global(.slick-arrow) {
    width: auto;
    height: auto;

    &::before {
      content: none;
    }
  }

  :global(.slick-prev) {
    left: -40px;
  }

  :global(.slick-next) {
    right: -40px;
  }

  @media (max-width: 1100px) {
    width: min(100%, 393px);
    margin: 0 auto;

    :global(.slick-prev) {
      left: calc(50% + -50px);
      bottom: -50px;
      top: unset;
    }

    :global(.slick-next) {
      right: calc(50% + -50px);
      bottom: -50px;
      top: unset;
    }
  }
}
