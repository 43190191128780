.container {
  width: min(872px, 100%);
  padding: 34px 51px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: var(--white);
  box-shadow: 0 7px 12px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  align-self: center;
  cursor: pointer;
  position: relative;

  @media (max-width: 1100px) {
    padding: 18px;
  }
}

.hidedContainer {
  padding-bottom: 22px;

  @media (max-width: 1100px) {
    padding-bottom: 6px;
  }
}

.headerBlock {
  display: flex;
  justify-content: space-between;
}

.header {
  font-size: 22px;
  line-height: 27px;
  font-family: "Inter7", sans-serif;
}

.text {
  font-size: 17px;
  line-height: 170%;
}

.rocket {
  position: absolute;
  right: 10px;
  bottom: 9px;
  visibility: visible;
  transition: all 0ms ease-out .2s;
}
